<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Marcas - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'product-brands'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-product-brand :productbrand="productbrand" :update="true"></form-product-brand>
</span>
</template>

<script>
import FormProductBrandComponent from "./partial/FormProductBrandComponent";

export default {
    name: "AddProductBrandComponent",
    created() {
        this.loadProductBrand()
    },
    props: {
        prod_b_id: {
            require: true
        }
    },
    data() {
        return {
            productbrand: {
                prod_b_id: '',
                prod_b_nome: '',
                prod_b_slug: '',
                prod_b_descricao: '',
                prod_b_logo: '',
                prod_b_banner: '',
                prod_b_status: false,
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            langs: true
        }
    },
    methods: {
        loadProductBrand() {
            this.$store.dispatch('loadProductBrand', this.prod_b_id)
                .then(response => this.productbrand = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formProductBrand: FormProductBrandComponent
    }
};
</script>

<style scoped>

</style>
